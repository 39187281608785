import { createSlice } from '@reduxjs/toolkit';

export const servicesSlice = createSlice({
    name:"services_slice",
    initialState:{
        services:localStorage.getItem('services_list') ? JSON.parse(localStorage.getItem('services_list')) : []
    },
    reducers:{
        addServicesToCart: (state,action) =>{
            state.services = [...state.services,action.payload]
            localStorage.setItem('services_list',JSON.stringify(state.services))
        },
        removeService:(state,action)=>{
            // get index
            let indexService = state.services.findIndex(item => item.id === action.payload);
            if(action.payload !== -1){
                state.services.splice(indexService, 1);
            }

            localStorage.setItem('services_list',JSON.stringify(state.services))
        }
    },
    extraReducers:{}
});

export const {addServicesToCart,removeService} = servicesSlice.actions;
export default servicesSlice.reducer;