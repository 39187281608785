import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const customerSlice = createSlice({
    name:"customer_slice",
    initialState:{
        services:0
    },
    reducers:{

    },
    extraReducers:{

    }
});

export const {} = customerSlice.actions;
export default customerSlice.reducer;