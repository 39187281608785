import React, {Suspense } from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useIsAuthenticated} from "react-auth-kit";

// Client 
const MainPage = React.lazy(()=> import('./pages/client/mainPage'));
const ServicesPage = React.lazy(()=> import('./pages/client/servicesPage'));
const CategoryServicesPage = React.lazy(()=> import('./pages/client/categoryServicePage'));
// Auth Page
const AuthPage = React.lazy(()=> import('./pages/auth/autentification'));
const ForgotPassword = React.lazy(()=> import('./pages/auth/forgotPassword'));
const ResetPassword = React.lazy((()=> import('./pages/auth/resetPassword')));
// Admin Page
const Dashboard = React.lazy(()=> import('./pages/admin/dashboard'));
const AdminServices = React.lazy(()=> import('./pages/admin/admin_services'));
const AdminCategory = React.lazy(()=> import('./pages/admin/admin_category'));
const AdminOrders = React.lazy(()=> import('./pages/admin/admin_order'));
const AdminPinned = React.lazy(()=> import('./pages/admin/admin_pinned'));
const CreateService = React.lazy(()=> import('./pages/admin/createServices'));
const CreateCategory = React.lazy(()=> import('./pages/admin/createCategory'));
const EditService = React.lazy(()=> import('./pages/admin/admin_edit_services'));

function App() {
   const RequireAuth = ({ children }) => {
      const isAuthenticated = useIsAuthenticated();
    
      if (!isAuthenticated()) {
        return <Navigate to="/" />;
      }
    
      return <>{children}</>;
   };

   return (
    <Suspense fallback={
      <div style={{width:'100%',height:'100vh',backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center'}}>
         <svg version="1.1" id="loader-1" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
            <path opacity="0.2" fill="#1F8A70" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
               s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
               c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
            <path fill="#1F8A70" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
               C22.32,8.481,24.301,9.057,26.013,10.047z">
            <animateTransform attributeType="xml"
               attributeName="transform"
               type="rotate"
               from="0 20 20"
               to="360 20 20"
               dur="0.5s"
               repeatCount="indefinite"
            />
            </path>
         </svg>
      </div>
    }>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
         <Route path="/"  element={<MainPage/>}/>
         <Route path="/services"  element={<ServicesPage/>}/>
         <Route path="/services/:slug" element={<CategoryServicesPage/>}/>
         <Route path="/login" element={<AuthPage/>}/>
         <Route path="/forgot-password" element={<ForgotPassword/>}/>
         <Route path="/reset-password/:resetToken" element={<ResetPassword/>}/>
         <Route path="/dashboard" element={
            <RequireAuth loginPath='/'>
               <Dashboard/>
            </RequireAuth>
         }/>
         <Route path="/admin-services" element={
            <RequireAuth loginPath='/'>
               <AdminServices/>
            </RequireAuth>
         }/>
         <Route path="/admin-create-services" element={
            <RequireAuth loginPath='/'>
               <CreateService/>
            </RequireAuth>
         }/>
         <Route path="/admin-category" element={
            <RequireAuth loginPath='/'>
               <AdminCategory/>
            </RequireAuth>
         }/>
         <Route path="/admin-create-category" element={
            <RequireAuth loginPath='/'>
               <CreateCategory/>
            </RequireAuth>
         }/>
         <Route path="/edit-service/:id" element={
            <RequireAuth loginPath='/'>
               <EditService/>
            </RequireAuth>
         }/>
         <Route path="/orders" element={
            <RequireAuth loginPath='/'>
               <AdminOrders/>
            </RequireAuth>
         }/>
         <Route path="/pinned" element={
            <RequireAuth loginPath='/'>
               <AdminPinned/>
            </RequireAuth>
         }/>
      </Routes>
    </Suspense>
  );
}

export default App;
